import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of, EMPTY } from 'rxjs';
import { SelectDialogComponent } from './select-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class SelectDialogService {
  constructor(private dialog: MatDialog) { }

  open(data: SelectDialogData): Observable<any> {
    const dialogRef = this.dialog.open(SelectDialogComponent, { data });
    return dialogRef.afterClosed().pipe(mergeMap(res => !!res ? of(res) : EMPTY));
  }
}

export interface SelectDialogData {
  icon: string;
  title: string;
  text?: string;
  label: string;
  displayField: string;
  list: any[];
}
