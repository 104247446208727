import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() icon: string;
  @Input() text: string;
  @Input() type = 'button';
  @Input() disabled = false;
  @Output() onClick = new EventEmitter();
}
