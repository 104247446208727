import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SelectDialogData } from './select-dialog.service';
import { Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-select-dialog',
  templateUrl: './select-dialog.component.html',
  styleUrls: ['../dialog-component.scss']
})
export class SelectDialogComponent implements OnInit, OnDestroy {
  form: FormGroup;
  filteredList: any[];
  private sub: Subscription | undefined;

  constructor(
    public dialogRef: MatDialogRef<SelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectDialogData
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      select: new FormControl(null, Validators.required),
      filter: new FormControl(null)
    });
    this.filteredList = this.data.list;
    this.sub = this.form.get('filter').valueChanges.subscribe(search => this.filteredList = !!search ?
      this.data.list.filter(item => item[this.data.displayField].toLowerCase().includes(search.toLowerCase())) : this.data.list
    );
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  confirm() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.get('select').value);
    } else {
      this.form.get('select').markAsTouched();
    }
  }
}
