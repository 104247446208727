import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { AuthModule } from './auth/auth.module';
import { SelectedMuseumState } from './state/selected-museum.state';
import { AuthState } from './auth/state/auth.state';
import { GlobalState } from './state/global.state';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FetchLocaleService } from './shared/utils/fetch-locale.service';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './auth/components/page-not-found/page-not-found.component';
import { PasswordChangeComponent } from './auth/components/password-change/password-change.component';
import { PasswordRecoveryComponent } from './auth/components/password-recovery/password-recovery.component';
import { SignInComponent } from './auth/components/sign-in/sign-in.component';
import { SignUpComponent } from './auth/components/sign-up/sign-up.component';
import { UnauthorizedComponent } from './auth/components/unauthorized/unauthorized.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    RouterModule.forRoot([
      { path: '403', component: UnauthorizedComponent },
      { path: '', redirectTo: '/signin', pathMatch: 'full' },
      { path: 'signin', component: SignInComponent },
      { path: 'signup', component: SignUpComponent },
      { path: 'signup/:token', component: SignUpComponent },
      { path: 'invitation/:email', component: SignUpComponent },
      { path: 'password-recovery', component: PasswordRecoveryComponent },
      { path: 'password-change/:token', component: PasswordChangeComponent },
      { path: 'dash', loadChildren: () => import('./dash/dash.module').then(m => m.DashModule) },
      { path: 'museum', loadChildren: () => import('./main/main.module').then(m => m.MainModule) },
      { path: '**', component: PageNotFoundComponent }
    ], { scrollPositionRestoration: 'top' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxsModule.forRoot(
      [GlobalState, SelectedMuseumState, AuthState], // they're needed across all the app
      { developmentMode: !environment.production }
    ),
    MatProgressBarModule,
    AuthModule,
    NgxsStoragePluginModule.forRoot({ key: ['auth', 'selectedMuseum', 'museums.searchKey'] }),
    environment.production ? [] : NgxsReduxDevtoolsPluginModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [FetchLocaleService],
      useFactory: (fetchLocaleService: FetchLocaleService) => fetchLocaleService.getBrowserLocale()
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
