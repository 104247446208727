import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-language-tabs',
  templateUrl: './language-tabs.component.html',
  styleUrls: ['./language-tabs.component.scss']
})
export class LanguageTabsComponent implements OnChanges {
  @Input() languages: string[];
  @Input() selected: string;
  @Output() selectedChange = new EventEmitter<string>();
  @Output() addTab = new EventEmitter<any>();
  selectedIndex = 0;

  ngOnChanges(): void {
    const selectedIndex = this.languages.indexOf(this.selected);
    this.selectedIndex = selectedIndex !== -1 ? selectedIndex : this.languages.length - 1;
  }

  switchLanguage(event: MatTabChangeEvent) {
    this.selectedChange.emit(event.tab.textLabel.toLowerCase());
  }
}
