import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { tap, mergeMap } from 'rxjs/operators';
import { preload, stopPreload } from 'src/app/state/global.state';
import { Injectable } from '@angular/core';
import { ResetGuides } from '../main/guides/state/guide.actions';
import { ResetPieces } from '../main/pieces/state/piece.actions';
import { ResetGuideContents } from '../main/guides/guide-detail/guide-contents/state/guide-content.actions';
import { ResetPieceContents } from '../main/pieces/piece-detail/piece-contents/state/piece-content.actions';
import { Museum } from '../dash/museums/state/museum.models';
import { MuseumProviderService } from '../dash/museums/state/museum-provider.service';
import { DeselectMuseum, FetchSelectedMuseum, FetchMuseumCredits, SelectMuseum } from './selected-museum.actions';
import { ResetMuseumContent } from '../main/museum-contents/state/museum-content.actions';
import { Observable, throwError } from 'rxjs';

export class SelectedMuseumStateModel extends Museum {
  credits?: number;
}

@State<SelectedMuseumStateModel>({
  name: 'selectedMuseum',
  defaults: null
})

@Injectable()
export class SelectedMuseumState {
  constructor(private store: Store, private museumProvider: MuseumProviderService) { }

  @Selector()
  static id(state: SelectedMuseumStateModel): number {
    return state?.id || -1;
  }

  @Selector()
  static title(state: SelectedMuseumStateModel): string {
    return state?.name || '';
  }

  @Selector()
  static qrPrefix(state: SelectedMuseumStateModel): string {
    return state?.qrPrefix || '';
  }

  @Selector()
  static nfcPrefix(state: SelectedMuseumStateModel): string {
    return state?.nfcPrefix || '';
  }

  @Selector()
  static credits(state: SelectedMuseumStateModel): number {
    return state?.credits || -1;
  }

  static defaultLanguage(store: Store): Observable<string> {
    return store.selectOnce(s => s.selectedMuseum.defaultLanguage);
  }

  static timezone(store: Store): Observable<string> {
    return store.selectOnce(s => s.selectedMuseum.timezone);
  }

  @Action(DeselectMuseum)
  deselect(ctx: StateContext<SelectedMuseumStateModel>): void {
    ctx.setState(null);
    this.store.dispatch([
      new ResetMuseumContent(),
      new ResetGuides(),
      new ResetGuideContents(),
      new ResetPieces(),
      new ResetPieceContents()
    ]);
  }

  @Action(SelectMuseum)
  select(ctx: StateContext<SelectedMuseumStateModel>, { payload }: SelectMuseum): void {
    ctx.setState(payload);
  }

  @Action(FetchSelectedMuseum)
  fetch(ctx: StateContext<SelectedMuseumStateModel>, { payload }: FetchSelectedMuseum): Observable<Museum> {
    if (!payload || ctx.getState().id === payload) {
      return;
    }
    return preload(ctx).pipe(
      mergeMap(() => this.museumProvider.getOne(payload)),
      tap(res => ctx.patchState(res)),
      stopPreload(ctx)
    );
  }

  @Action(FetchMuseumCredits)
  fetchCredits(ctx: StateContext<SelectedMuseumStateModel>, { force }: FetchMuseumCredits): Observable<number> {
    const id: number = ctx.getState().id;
    if (!id) {
      return throwError(() => 'id_not_found_error');
    }
    if (!!ctx.getState().credits && !force) {
      return;
    }
    return preload(ctx).pipe(
      mergeMap(() => this.museumProvider.getCredits(id)),
      tap(credits => ctx.patchState({ credits })),
      stopPreload(ctx)
    );
  }
}
