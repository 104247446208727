import { Observable, of } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';

export class RouteUtils {
  // This method returns URL params or null if not found
  public static getParams(route: ActivatedRoute): Observable<Params | null> {
    return of(route?.snapshot?.params);
  }
  // This method returns a URL param or null if not found
  // It's meant to be used to start RX chains based on a parameter
  public static getParam(route: ActivatedRoute, paramKey: string): Observable<string | null> {
    return of(route?.snapshot?.params?.[paramKey]);
  }
}
