import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent {
  @Input() icon: string;
  @Input() text: string;
  @Input() disabled = false;
  @Output() onClick = new EventEmitter();
}
