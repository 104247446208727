import { ErrorInterceptorService } from './interceptors/error-interceptor.service';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PasswordRecoveryComponent } from './components/password-recovery/password-recovery.component';
import { PasswordChangeComponent } from './components/password-change/password-change.component';
import { IfAuthDirective } from './if-auth.directive';
import { AuthInterceptorService } from './interceptors/auth-interceptor.service';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SharedModule } from '../shared/shared.module';
import { HasAuthorizationGuardService } from './route-guards/has-authorization-guard.service';
import { IsLoggedGuardService } from './route-guards/is-logged-guard.service';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { AuthProviderService } from './state/auth-provider.service';

@NgModule({
  imports: [SharedModule],
  declarations: [
    SignInComponent,
    SignUpComponent,
    UnauthorizedComponent,
    PageNotFoundComponent,
    PasswordRecoveryComponent,
    PasswordChangeComponent,
    IfAuthDirective
  ],
  providers: [
    IsLoggedGuardService,
    HasAuthorizationGuardService,
    AuthProviderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    },
  ],
  exports: [IfAuthDirective]
})
export class AuthModule { }
