import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { ToastService } from 'src/app/shared/presentation/toast.service';
import { Subscription } from 'rxjs';
import { PasswordRecoveryRequest } from '../../state/auth.actions';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html'
})
export class PasswordRecoveryComponent implements OnInit, OnDestroy {
  emailSent: boolean;
  form: FormGroup;
  private sub: Subscription | undefined;

  constructor(private router: Router, private store: Store, private toast: ToastService) {
    this.emailSent = false;
  }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  submitForm() {
    if (this.form.valid) {
      this.sub = this.store.dispatch(new PasswordRecoveryRequest(this.form.get('email').value)).subscribe({
        next: () => this.emailSent = true,
        error: () => this.toast.error('[forgot-password].recovery email error')
      });
    }
  }

  back() {
    this.router.navigateByUrl('signin');
  }
}
