import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { Logout } from '../../state/auth.actions';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnDestroy {
  private sub: Subscription | undefined;
  
  constructor(private store: Store, private router: Router) { }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  toLogin() {
    this.sub = this.store.dispatch(new Logout()).subscribe(() => this.router.navigateByUrl('signin'));
  }
}
