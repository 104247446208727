import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { RouteUtils } from 'src/app/shared/utils/route-utils';
import { FormUtilities } from 'src/app/shared/input/form/form-utilities';
import { ToastService } from 'src/app/shared/presentation/toast.service';
import { UpdatePassword } from '../../state/auth.actions';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent implements OnInit, OnDestroy {
  form: FormGroup;
  displayError: boolean;
  token: string;
  private subs: Subscription[];

  constructor(private store: Store, private toast: ToastService, private router: Router, private route: ActivatedRoute) {
    this.displayError = false;
    this.subs = [];
  }

  ngOnInit() {
    this.form = new FormGroup({
      passwords: new FormGroup(
        {
          password: new FormControl('', [Validators.required, Validators.minLength(8)]),
          confirmPassword: new FormControl('', Validators.required)
        },
        FormUtilities.matchPasswordValidator
      ),
      showStuff: new FormControl(false)
    });
    this.subs.push(RouteUtils.getParam(this.route, 'token').subscribe(token => this.token = token));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  submitForm() {
    if (this.form.valid) {
      this.subs.push(this.store.dispatch(new UpdatePassword({
        token: this.token,
        password: this.form.get('passwords.password').value
      })).subscribe({
        next: () => {
          this.toast.success('[forgot-password].update password success');
          this.toLogin();
        },
        error: err => err.status === 401 ? this.displayError = true : this.toast.error('[forgot-password].update password error')
      }));
    }
  }

  toLogin() {
    this.router.navigateByUrl('signin');
  }

  toPasswordRecovery() {
    this.router.navigateByUrl('password-recovery');
  }
}
