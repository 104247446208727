import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

export class CallOptions {
  headers?: any;
  params?: any;
  search?: string;
  retry?: number;

  constructor(options: CallOptions) {
    Object.assign(this, options);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiClientService {
  constructor(private http: HttpClient) { }

  get(path: string, options?: CallOptions): Observable<any> {
    return this.http.get(path, options).pipe(catchError(error => throwError(() => error)));
  }

  post(path: string, body: any, options?: CallOptions): Observable<any> {
    return this.http.post(path, body, options).pipe(catchError(error => throwError(() => error)));
  }

  put(path: string, body: any, options?: CallOptions): Observable<any> {
    return this.http.put(path, body, options).pipe(catchError(error => throwError(() => error)));
  }

  patch(path: string, body: any, options?: CallOptions): Observable<any> {
    return this.http.patch(path, body, options).pipe(catchError(error => throwError(() => error)));
  }

  delete(path: string, options?: CallOptions): Observable<any> {
    return this.http.delete(path, options).pipe(catchError(error => throwError(() => error)));
  }
}
