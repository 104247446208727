import { Account } from "../../accounts/state/account-provider.service";

export class Museum {
  id: number;
  name: string;
  defaultLanguage: string;
  address: string;
  timezone: string;
  lat: number;
  lon: number;
  geocoded?: boolean;
  qrEnabled?: boolean;
  qrPrefix?: string;
  nfcEnabled?: boolean;
  nfcPrefix?: string;
  createdDate?: Date;
  authorizationList?: MuseumAuthorization[];
}

export interface MuseumAuthorization {
  id: number;
  accountId?: number;
  appId: number;
  role: string;
  email: string;
  createdDate: Date;
  updatedDate: Date;
  account?: Account;
}

export interface UpdateMuseumRequest {
  id: number;
  body: Museum;
}

export interface AssignMuseumRequest {
  id: number;
  userId: number;
}

export interface MuseumInviteRequest {
  id: number;
  email: string;
  role: MuseumRole;
}

export interface MuseumRemoveInviteRequest {
  appId: number;
  invitationId: number;
}


export enum MuseumRole {
  ADMIN = 'admin',
  USER = 'user'
}
