import { Router } from '@angular/router';
import { catchError, mergeMap } from 'rxjs/operators';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { AuthState } from '../state/auth.state';
import { Account } from 'src/app/dash/accounts/state/account-provider.service';
import { Logout, RefreshToken } from '../state/auth.actions';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {
  @Select(AuthState) auth$: Observable<Account>;
  private user: Account;

  constructor(private store: Store, private router: Router) {
    this.auth$.subscribe(res => this.user = res);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => {
      if (request.url.includes('auth/') && !request.url.includes('resend_confirmation')) {
        if (request.url.includes('refresh')) {
          this.router.navigateByUrl('signin');
          this.store.dispatch(new Logout());
        }
        return throwError(() => error);
      }
      if (!this.user.token) {
        this.router.navigate(['./403']);
      }
      if (error.status !== 401) {
        return throwError(() => error);
      }
      return this.store.dispatch(new RefreshToken(this.user.refreshToken)).pipe(mergeMap(() => {
        request = request.clone({
          setHeaders: { Authorization: `Bearer ${this.user.token}` }
        });
        return next.handle(request);
      }));
    }));
  }
}
