import { Component, Inject } from "@angular/core";
import { ClipboardService } from "ngx-clipboard";
import { NfcDialogData } from "./nfc-dialog.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-nfc-dialog',
  templateUrl: './nfc-dialog.component.html',
  styleUrls: ['../dialog-component.scss'],
})
export class NfcDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<NfcDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NfcDialogData,
    private clipboard: ClipboardService
  ) { }

  copy(payload: string) {
    this.clipboard.copyFromContent(payload);
  }
}
