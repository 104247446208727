import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBlockComponent } from './form/form-block/form-block.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PresentationModule } from '../presentation/presentation.module';
import { ButtonModule } from '../button/button.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ErrorComponent } from './error/error.component';
import { SearchComponent } from './search/search.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { QuillModule } from 'ngx-quill';

@NgModule({
  declarations: [SearchComponent, ErrorComponent, FormBlockComponent],
  imports: [
    CommonModule,
    TranslateModule,
    PresentationModule,
    ButtonModule,
    QuillModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatChipsModule,
    DragDropModule,
  ],
  exports: [
    SearchComponent,
    ErrorComponent,
    FormBlockComponent,
    QuillModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDatepickerModule,
    MatChipsModule,
    DragDropModule,
  ]
})
export class InputModule { }
