export class ResetCurrentSection {
  static readonly type = '[Global] Reset current section';
}

export class SetCurrentSection {
  static readonly type = '[Global] Set current section';
  constructor(public payload: string) {}
}

export class ResetLoading {
  static readonly type = '[Global] Reset Loading';
}

export class EnableLoading {
  static readonly type = '[Global] Enable Loading';
}

export class DisableLoading {
  static readonly type = '[Global] Disable Loading';
}
