import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { NfcDialogComponent } from "./nfc-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})
export class NfcDialogService {
  constructor(private dialog: MatDialog) {}

  open(data: NfcDialogData): Observable<any> {
    const dialogRef = this.dialog.open(NfcDialogComponent, { data });
    return dialogRef.afterClosed();
  }
}

export interface NfcDialogData {
  title?: string;
  text?: string;
  link: string;
}
