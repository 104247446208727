import { MuseumContent, UpdateMuseumContentRequest } from './museum-content.models';

export class ResetMuseumContent {
  static readonly type = '[museums] Reset Content';
}

export class ResetMuseumContentsDraft {
  static readonly type = '[museums] Reset Content Draft';
}

export class FetchAllMuseumContents {
  static readonly type = '[museums] Fetch All Contents';
  constructor(public force?: boolean) { }
}

export class FetchMuseumContent {
  static readonly type = '[museums] Fetch Content';
  constructor(public payload: number) { }
}

export class FetchMuseumContentDraft {
  static readonly type = '[museums] Fetch Content Draft';
  constructor(public payload: string) { }
}

export class CreateMuseumContent {
  static readonly type = '[museums] Create Content';
  constructor(public payload: MuseumContent) { }
}

export class CreateMuseumContentDraft {
  static readonly type = '[museums] Create Content Draft';
  constructor(public payload: string) { }
}

export class UpdateMuseumContent {
  static readonly type = '[museums] Update Content';
  constructor(public payload: UpdateMuseumContentRequest) { }
}

export class DeleteMuseumContent {
  static readonly type = '[museums] Delete Content';
  constructor(public payload: number) { }
}

export class DeleteMuseumContentDraft {
  static readonly type = '[museums] Delete Content Draft';
  constructor(public payload: string) { }
}
