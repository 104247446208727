import { Injectable } from '@angular/core';
import { EMPTY, Observable, mergeMap, of } from 'rxjs';
import { MultipleSelectDialogComponent } from './multiple-select-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class MultipleSelectDialogService {
  constructor(private dialog: MatDialog) { }

  open(data: MultipleSelectDialogData): Observable<any> {
    const dialogRef = this.dialog.open(MultipleSelectDialogComponent, { data });
    return dialogRef.afterClosed().pipe(mergeMap(res => !!res ? of(res) : EMPTY));
  }
}

export interface MultipleSelectDialogData {
  icon: string;
  title: string;
  text?: string;
  label: string;
  displayField: string;
  required: boolean;
  selectedValues?: any[];
  list$: Observable<any[]>;
}
