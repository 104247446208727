import { QrDialogComponent } from "./qr-dialog.component";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})
export class QrDialogService {

  constructor(private dialog: MatDialog) { }

  open(data: QRDialogData): Observable<any> {
    const dialogRef = this.dialog.open(QrDialogComponent, { data });
    return dialogRef.afterClosed();
  }
}

export interface QRDialogData {
  payload: string;
  downloadEnabled: boolean;
  title?: string;
  text?: string;
}
