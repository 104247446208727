import { ApiClientService, CallOptions } from 'src/app/shared/utils/api-client.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthCredentials, AuthResponse, SignUpRequest, NewPasswordRequest, AuthRefreshResponse } from './auth.models';

@Injectable({
  providedIn: 'root'
})
export class AuthProviderService {
  private baseUrl: string;

  constructor(private apiClient: ApiClientService) {
    this.baseUrl = `${environment.API_ENDPOINT}/auth`;
  }

  login(credentials: AuthCredentials, options?: CallOptions): Observable<AuthResponse> {
    return this.apiClient.post(`${this.baseUrl}/login`, credentials, options);
  }

  signup(data: SignUpRequest, options?: CallOptions): Observable<any> {
    return this.apiClient.post(`${this.baseUrl}/register`, data, options);
  }

  confirmRegistration(token: string, options?: CallOptions): Observable<AuthResponse> {
    return this.apiClient.get(`${this.baseUrl}/confirm_registration/${token}`, options);
  }

  sendRecoveryEmail(email: string, options?: CallOptions): Observable<any> {
    return this.apiClient.post(`${this.baseUrl}/forgot_password`, { email }, options);
  }

  updatePassword(data: NewPasswordRequest, options?: CallOptions): Observable<any> {
    return this.apiClient.post(`${this.baseUrl}/new_password`, data, options);
  }

  refreshToken(refreshToken: string, options?: CallOptions): Observable<AuthRefreshResponse> {
    return this.apiClient.post(`${this.baseUrl}/refresh`, { refreshToken }, options);
  }

  resendConfirmationEmail(email: string, options?: CallOptions): Observable<any> {
    return this.apiClient.post(`${this.baseUrl}/resend_confirmation`, { email }, options);
  }
}
