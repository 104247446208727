import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { forwardRef } from '@angular/core';

export class GenericFormControlAccessors {
  static valueAndValidator(T: any) {
    return [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => T),
        multi: true
      },
      {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => T),
        multi: true
      }
    ];
  }

  static value(T: any) {
    return [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => T),
        multi: true
      }
    ];
  }
}
