import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule, MatRipple } from '@angular/material/core';
import { PresentationModule } from '../presentation/presentation.module';
import { ButtonComponent } from './button/button.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { AnchorComponent } from './anchor/anchor.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AnchorComponent, ButtonComponent, IconButtonComponent],
  imports: [CommonModule, MatButtonModule, MatTooltipModule, TranslateModule, PresentationModule, MatRippleModule],
  exports: [IconButtonComponent, ButtonComponent, AnchorComponent, MatRipple]
})
export class ButtonModule { }
