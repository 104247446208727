import { Component, ElementRef, AfterViewInit, ViewChild, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-fixed',
  templateUrl: './fixed.component.html',
  styleUrls: ['./fixed.component.scss']
})
export class FixedComponent implements AfterViewInit {
  @ViewChild('content', { static: false }) content: ElementRef;
  @ViewChild('spacer', { static: false }) spacer: ElementRef;

  constructor(private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    this.onResized();
  }

  onResized() {
    this.renderer.setStyle(this.spacer.nativeElement, 'min-height', this.content.nativeElement.offsetHeight + 'px');
  }
}
