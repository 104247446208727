import { AuthCredentials, NewPasswordRequest, SignUpRequest } from "./auth.models";

export class Login {
  static readonly type = '[Auth] Login';
  constructor(public payload: AuthCredentials) { }
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class Signup {
  static readonly type = '[Auth] Sign up';
  constructor(public payload: SignUpRequest) { }
}

export class PasswordRecoveryRequest {
  static readonly type = '[Auth] Password Recovery Request';
  constructor(public payload: string) { }
}

export class UpdatePassword {
  static readonly type = '[Auth] Update Password';
  constructor(public payload: NewPasswordRequest) { }
}

export class ConfirmRegistration {
  static readonly type = '[Auth] Confirm Registration';
  constructor(public payload: string) { }
}

export class RefreshToken {
  static readonly type = '[Auth] Refresh Token';
  constructor(public payload: string) { }
}

export class ResendConfirmationRequest {
  static readonly type = '[Auth] Resend Confirmation Request';
  constructor(public payload: string) { }
}