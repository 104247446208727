import { Injectable } from '@angular/core';
import { EMPTY, Observable, mergeMap, of } from 'rxjs';
import { JsonDialogComponent } from './json-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class JsonDialogService {
  constructor(private dialog: MatDialog) { }

  open(data: any): Observable<any> {
    const dialogRef = this.dialog.open(JsonDialogComponent, { data });
    return dialogRef.afterClosed().pipe(mergeMap(res => !!res ? of(res) : EMPTY));
  }
}
