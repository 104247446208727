import { Component, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-anchor',
  templateUrl: './anchor.component.html',
  styleUrls: ['./anchor.component.scss']
})
export class AnchorComponent {
  @Input() unlinkedText: string;
  @Input() text: string;
  @Output() onClick = new EventEmitter();
}
