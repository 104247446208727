import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-json-dialog',
  templateUrl: './json-dialog.component.html',
  styleUrls: ['../dialog-component.scss']
})
export class JsonDialogComponent {
  constructor(public dialogRef: MatDialogRef<JsonDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }
}
