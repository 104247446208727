import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogData } from './confirm-dialog.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['../dialog-component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) { }

  ngOnInit() {
    this.form = new FormGroup({
      confirm: new FormControl('', [Validators.required, Validators.pattern(this.data.confirmByTyping)])
    });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    if (!this.data.confirmByTyping || this.form.valid) {
      this.dialogRef.close(true);
    } else {
      this.form.get('confirm').markAsTouched();
    }
  }

}
