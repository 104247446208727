import { AccountUtilsService } from './account-utils.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PwaUpdateService } from './pwa-update.service';
import { FetchLocaleService } from './fetch-locale.service';
import { ApiClientService } from './api-client.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [],
  providers: [PwaUpdateService, FetchLocaleService, AccountUtilsService, ApiClientService],
  imports: [CommonModule, MatSnackBarModule]
})
export class UtilsModule { }
