import { FetchAllGuideContentsRequest, GuideContent, UpdateGuideContentRequest } from "./guide-content.models";

export class ResetGuideContents {
  static readonly type = '[Guides] Reset Content';
}

export class ResetGuideContentsDraft {
  static readonly type = '[Guides] Reset Content Draft';
}

export class FetchAllGuideContents {
  static readonly type = '[Guides] Fetch All Contents';
  constructor(public payload: FetchAllGuideContentsRequest) { }
}

export class FetchGuideContent {
  static readonly type = '[Guides] Fetch Content';
  constructor(public payload: number) { }
}

export class FetchGuideContentDraft {
  static readonly type = '[Guides] Fetch Content Draft';
  constructor(public payload: string) { }
}

export class CreateGuideContent {
  static readonly type = '[Guides] Create Content';
  constructor(public payload: GuideContent) { }
}

export class CreateGuideContentDraft {
  static readonly type = '[Guides] Create Content Draft';
  constructor(public payload: string) { }
}

export class UpdateGuideContent {
  static readonly type = '[Guides] Update Content';
  constructor(public payload: UpdateGuideContentRequest) { }
}

export class DeleteGuideContent {
  static readonly type = '[Guides] Delete Content';
  constructor(public payload: number) { }
}

export class DeleteGuideContentDraft {
  static readonly type = '[Guides] Delete Content Draft';
  constructor(public payload: string) { }
}