import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../button/button.module';
import { PresentationModule } from '../presentation/presentation.module';
import { InputModule } from '../input/input.module';
import { ConfirmDialogService } from './confirm-dialog/confirm-dialog.service';
import { SelectDialogComponent } from './select-dialog/select-dialog.component';
import { SelectDialogService } from './select-dialog/select-dialog.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { InvitationDialogComponent } from './invitation-dialog/invitation-dialog.component';
import { InvitationDialogService } from './invitation-dialog/invitation-dialog.service';
import { MultipleSelectDialogComponent } from './multiple-select-dialog/multiple-select-dialog.component';
import { MultipleSelectDialogService } from './multiple-select-dialog/multiple-select-dialog.service';
import { MatIconModule } from '@angular/material/icon';
import { QrDialogComponent } from './qr-dialog/qr-dialog.component';
import { QrDialogService } from './qr-dialog/qr-dialog.service';
import { QRCodeModule } from 'angularx-qrcode';
import { NfcDialogComponent } from './nfc-dialog/nfc-dialog.component';
import { NfcDialogService } from './nfc-dialog/nfc-dialog.service';
import { JsonDialogComponent } from './json-dialog/json-dialog.component';
import { JsonDialogService } from './json-dialog/json-dialog.service';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    JsonDialogComponent,
    SelectDialogComponent,
    InvitationDialogComponent,
    MultipleSelectDialogComponent,
    QrDialogComponent,
    NfcDialogComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    ButtonModule,
    PresentationModule,
    InputModule,
    NgxMatSelectSearchModule,
    MatIconModule,
    QRCodeModule,
    TranslateModule
  ],
  providers: [
    ConfirmDialogService,
    QrDialogService,
    SelectDialogService,
    InvitationDialogService,
    MultipleSelectDialogService,
    NfcDialogService,
    JsonDialogService
  ]
})
export class DialogModule { }
