import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SelectLanguageDialogData } from './select-language-dialog.service';
import { FormUtilities } from '../../input/form/form-utilities';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-select-language-dialog',
  templateUrl: './select-language-dialog.component.html',
  styleUrls: ['./select-language-dialog.component.scss']
})
export class SelectLanguageDialogComponent implements OnInit {
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<SelectLanguageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectLanguageDialogData
  ) { }

  ngOnInit() {
    this.form = new FormGroup({ select: new FormControl(null, Validators.required) });
  }

  confirm() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.get('select').value);
    } else {
      FormUtilities.markFormAsTouched(this.form);
    }
  }
}
