import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PresentationModule } from '../presentation/presentation.module';
import { TranslateModule } from '@ngx-translate/core';
import { ActionsComponent } from './actions/actions.component';
import { FixedComponent } from './fixed/fixed.component';
import { HeaderComponent } from './header/header.component';
import { MenubarComponent } from './menubar/menubar.component';
import { ServicePageComponent } from './service-page/service-page.component';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [ServicePageComponent, MenubarComponent, HeaderComponent, FixedComponent, ActionsComponent],
  imports: [CommonModule, PresentationModule, TranslateModule, MatTabsModule],
  exports: [ServicePageComponent, MenubarComponent, HeaderComponent, FixedComponent, ActionsComponent, MatTabsModule]
})
export class PageStructureModule { }
