import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonModule } from "../button/button.module";
import { InputModule } from "../input/input.module";
import { LanguageTabsComponent } from "./language-tabs/language-tabs.component";
import { LanguageSelectorComponent } from "./language-selector/language-selector.component";
import { MatIconModule } from "@angular/material/icon";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { PresentationModule } from "../presentation/presentation.module";
import { SelectLanguageDialogService } from "./select-language-dialog/select-language-dialog.service";
import { SelectLanguageDialogComponent } from "./select-language-dialog/select-language-dialog.component";
import { MatTabsModule } from "@angular/material/tabs";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
  declarations: [LanguageSelectorComponent, LanguageTabsComponent, SelectLanguageDialogComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ButtonModule,
    PresentationModule,
    InputModule,
    MatDialogModule,
    NgxMatSelectSearchModule,
    MatIconModule,
    MatTabsModule
  ],
  exports: [LanguageSelectorComponent, LanguageTabsComponent],
  providers: [SelectLanguageDialogService]
})
export class LanguageModule { }
