export interface AuthResponse {
  token: string;
  refreshToken: string;
}

export interface AuthRefreshResponse {
  token: string;
}

export interface AuthCredentials {
  email: string;
  password: string;
}

export interface NewPasswordRequest {
  token: string;
  password: string;
}

export interface SignUpRequest {
  name: string;
  email: string;
  password: string;
}

export enum Role {
  ROOT = 'root',
  USER = 'user'
}
