import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Store, Select } from '@ngxs/store';
import { AuthState } from '../../state/auth.state';
import { Subscription, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { FormUtilities } from 'src/app/shared/input/form/form-utilities';
import { ToastService } from 'src/app/shared/presentation/toast.service';
import { Account } from 'src/app/dash/accounts/state/account-provider.service';
import { Role } from '../../state/auth.models';
import { Login } from '../../state/auth.actions';

@Component({
  selector: 'app-sign-in',
  styleUrls: ['./sign-in.component.scss'],
  templateUrl: './sign-in.component.html'
})
export class SignInComponent implements OnInit, OnDestroy {
  @Select(AuthState) user$: Observable<Account>;
  loginForm: FormGroup;
  private subs: Subscription[];

  constructor(private router: Router, private store: Store, private toast: ToastService) {
    this.subs = [];
  }

  ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });
    this.subs.push(this.user$.pipe(delay(0)).subscribe(res => {
      if (!res.role) {
        return;
      }
      switch (res.role) {
        case Role.ROOT:
        case Role.USER:
          this.router.navigateByUrl('dash');
          break;
        default:
          this.toast.error('[signin].invalid role error');
          break;
      }
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  forgot() {
    this.router.navigateByUrl('password-recovery');
  }

  signUp() {
    this.router.navigateByUrl('signup');
  }

  submitForm() {
    if (!this.loginForm.valid) {
      FormUtilities.markFormAsTouched(this.loginForm);
      this.toast.error('check form fields');
      return;
    }
    this.subs.push(this.store.dispatch(new Login(this.loginForm.value)).subscribe({
      error: e =>
        this.toast.error(e.status === 401 || e.status === 403 || e.status === 404 ? '[signin].login failed' : '[signin].communication error')
    }));
  }
}
