import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Select } from '@ngxs/store';
import { Account } from 'src/app/dash/accounts/state/account-provider.service';
import { AuthState } from '../state/auth.state';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  @Select(AuthState) auth$: Observable<Account>;
  private token: string;

  constructor() {
    this.auth$.subscribe(res => this.token = res.token);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!!this.token && request.url.includes(environment.API_ENDPOINT) && !request.url.includes('refresh')) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${this.token}` }
      });
    }
    return next.handle(request);
  }
}
