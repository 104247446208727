import { Piece, UpdatePieceRequest } from "./piece.models";

export class ResetPieces {
  static readonly type = '[Pieces] Reset';
}

export class FetchAllPieces {
  static readonly type = '[Pieces] Fetch All';
  constructor(public force?: boolean) { }
}

export class FetchPiece {
  static readonly type = '[Pieces] Fetch';
  constructor(public payload: number) { }
}

export class CreatePiece {
  static readonly type = '[Pieces] Create';
  constructor(public payload: Piece) { }
}

export class UpdatePiece {
  static readonly type = '[Pieces] Update';
  constructor(public payload: UpdatePieceRequest) { }
}

export class DeletePiece {
  static readonly type = '[Pieces] Delete';
  constructor(public payload: number) { }
}
