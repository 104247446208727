import { Injectable } from '@angular/core';
import { ApiClientService, CallOptions } from 'src/app/shared/utils/api-client.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Museum } from './museum.models';

@Injectable({
  providedIn: 'root'
})
export class MuseumProviderService {
  private baseUrl: string;

  constructor(private apiClient: ApiClientService) {
    this.baseUrl = `${environment.API_ENDPOINT}/museum`;
  }

  getAll(options?: CallOptions): Observable<Museum[]> {
    return this.apiClient.get(this.baseUrl, options);
  }

  getOne(id: number, options?: CallOptions): Observable<Museum> {
    return this.apiClient.get(`${this.baseUrl}/${id}`, options);
  }

  getCredits(id: number, options?: CallOptions): Observable<number> {
    return this.apiClient.get(`${this.baseUrl}/${id}/credits`, options);
  }

  create(data: Museum, options?: CallOptions): Observable<Museum> {
    return this.apiClient.post(this.baseUrl, data, options);
  }

  update(id: number, data: Museum, options?: CallOptions): Observable<Museum> {
    return this.apiClient.patch(`${this.baseUrl}/${id}`, data, options);
  }

  invite(id: number, email: string, role: string, options?: CallOptions): Observable<any> {
    return this.apiClient.put(`${this.baseUrl}/${id}/invite`, { email, role }, options);
  }

  removeInvitation(id: number, options?: CallOptions): Observable<any> {
    return this.apiClient.delete(`${this.baseUrl}/remove_invitation/${id}`, options);
  }

  delete(id: number, options?: CallOptions): Observable<any> {
    return this.apiClient.delete(`${this.baseUrl}/${id}`, options);
  }
}
