import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { SpinnerComponent } from './spinner/spinner.component';
import { TranslateModule } from '@ngx-translate/core';
import { ToastService } from './toast.service';
import { IconComponent } from './icon/icon.component';
import { TileComponent } from './tile/tile.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [IconComponent, SpinnerComponent, TileComponent],
  providers: [ToastService],
  imports: [CommonModule, MatIconModule, MatTooltipModule, TranslateModule],
  exports: [IconComponent, SpinnerComponent, TileComponent, MatTooltipModule]
})
export class PresentationModule { }
