import { State, Action, StateContext, Store } from '@ngxs/store';
import { tap, mergeMap } from 'rxjs/operators';
import { Login, Logout, Signup, PasswordRecoveryRequest, UpdatePassword, ConfirmRegistration, RefreshToken, ResendConfirmationRequest } from './auth.actions';
import { stopPreload, preload } from '../../state/global.state';
import { Injectable } from '@angular/core';
import { Account, AccountProviderService } from '../../dash/accounts/state/account-provider.service';
import { DeselectMuseum } from '../../state/selected-museum.actions';
import { Observable } from 'rxjs';
import { AuthRefreshResponse } from './auth.models';
import { AuthProviderService } from './auth-provider.service';

@State<Account>({
  name: 'auth'
})
@Injectable()
export class AuthState {
  constructor(private store: Store, private authProvider: AuthProviderService, private accountProvider: AccountProviderService) { }

  @Action(Login)
  login(ctx: StateContext<Account>, { payload }: Login): Observable<Account> {
    return preload(ctx).pipe(
      mergeMap(() => this.authProvider.login(payload)),
      mergeMap(auth => {
        ctx.setState(auth);
        return this.accountProvider.getMe();
      }),
      tap(account => ctx.patchState(account)),
      stopPreload(ctx)
    );
  }

  @Action(Logout)
  logout(ctx: StateContext<Account>): void {
    this.store.dispatch(new DeselectMuseum());
    ctx.setState({});
  }

  @Action(Signup)
  signup(ctx: StateContext<Account>, { payload }: Signup): Observable<any> {
    return preload(ctx).pipe(
      mergeMap(() => this.authProvider.signup(payload)),
      stopPreload(ctx)
    );
  }

  @Action(PasswordRecoveryRequest)
  passwordRecoveryRequest(ctx: StateContext<Account>, { payload }: PasswordRecoveryRequest): Observable<any> {
    return preload(ctx).pipe(
      mergeMap(() => this.authProvider.sendRecoveryEmail(payload)),
      stopPreload(ctx)
    );
  }

  @Action(UpdatePassword)
  updatePassword(ctx: StateContext<Account>, { payload }: UpdatePassword): Observable<any> {
    return preload(ctx).pipe(
      mergeMap(() => this.authProvider.updatePassword(payload)),
      stopPreload(ctx)
    );
  }

  @Action(ConfirmRegistration)
  confirmRegistration(ctx: StateContext<Account>, { payload }: ConfirmRegistration): Observable<Account> {
    return preload(ctx).pipe(
      mergeMap(() => this.authProvider.confirmRegistration(payload)),
      mergeMap(auth => {
        ctx.setState(auth);
        return this.accountProvider.getMe();
      }),
      tap(account => ctx.patchState(account)),
      stopPreload(ctx)
    );
  }

  @Action(RefreshToken)
  refreshToken(ctx: StateContext<Account>, { payload }: RefreshToken): Observable<AuthRefreshResponse> {
    return preload(ctx).pipe(
      mergeMap(() => this.authProvider.refreshToken(payload)),
      tap(auth => ctx.patchState(auth)),
      stopPreload(ctx)
    );
  }

  @Action(ResendConfirmationRequest)
  resendConfirmation(ctx: StateContext<Account>, { payload }: ResendConfirmationRequest): Observable<any> {
    return preload(ctx).pipe(
      mergeMap(() => this.authProvider.resendConfirmationEmail(payload)),
      stopPreload(ctx)
    );
  }
}
