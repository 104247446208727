import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { InvitationDialogComponent } from './invitation-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class InvitationDialogService {
  constructor(private dialog: MatDialog) {}

  open(data: InvitationDialogData): Observable<any> {
    const dialogRef = this.dialog.open(InvitationDialogComponent, { data });
    return dialogRef.afterClosed().pipe(mergeMap(res => !!res ? of(res) : EMPTY));
  }
}

export interface InvitationDialogData {
  icon: string;
  title: string;
  text?: string;
  placeholder?: string;
}
