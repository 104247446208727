import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from './button/button.module';
import { DialogModule } from './dialog/dialog.module';
import { InputModule } from './input/input.module';
import { PresentationModule } from './presentation/presentation.module';
import { PageStructureModule } from './page-structure/page-structure.module';
import { UtilsModule } from './utils/utils.module';
import { LanguageModule } from './language/language.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ButtonModule,
    DialogModule,
    InputModule,
    PageStructureModule,
    PresentationModule,
    UtilsModule,
    LanguageModule
  ],
  exports: [
    CommonModule,
    TranslateModule,
    ButtonModule,
    DialogModule,
    InputModule,
    PageStructureModule,
    PresentationModule,
    UtilsModule,
    LanguageModule
  ]
})
export class SharedModule { }
