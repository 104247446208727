import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClientService, CallOptions } from 'src/app/shared/utils/api-client.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountProviderService {
  private baseUrl: string;

  constructor(private apiClient: ApiClientService) {
    this.baseUrl = `${environment.API_ENDPOINT}/account`;
  }

  getAll(options?: CallOptions): Observable<Account[]> {
    return this.apiClient.get(this.baseUrl, options);
  }

  getMe(options?: CallOptions): Observable<Account> {
    return this.apiClient.get(`${this.baseUrl}/me`, options);
  }
}

export interface Account {
  id?: number;
  name?: string;
  email?: string;
  createdDate?: string;
  role?: string;
  active?: boolean;
  confirmed?: boolean;
  token?: string;
  refreshToken?: string;
}
