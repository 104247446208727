import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FetchLocaleService {
  getBrowserLocale(): string {
    return navigator.language.startsWith('it') ? 'it' : 'en';
  }
}
