import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormUtilities } from '../../input/form/form-utilities';
import { MuseumRole } from 'src/app/dash/museums/state/museum.models';
import { InvitationDialogData } from './invitation-dialog.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-invitation-dialog',
  templateUrl: './invitation-dialog.component.html',
  styleUrls: ['../dialog-component.scss']
})
export class InvitationDialogComponent implements OnInit {
  form: FormGroup;
  roles: MuseumRole[];

  constructor(public dialogRef: MatDialogRef<InvitationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InvitationDialogData
  ) {
    this.roles = Object.values(MuseumRole);
  }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      role: new FormControl(null, Validators.required)
    });
  }

  cancel() {
    this.dialogRef.close(null);
  }

  submit() {
    if (!this.form.valid) {
      FormUtilities.markFormAsTouched(this.form);
      return;
    }
    this.dialogRef.close(this.form.value);
  }
}
