import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { ResetCurrentSection, SetCurrentSection, EnableLoading, DisableLoading, ResetLoading } from './global.actions';
import { TranslateService } from '@ngx-translate/core';
import { delay, tap, finalize } from 'rxjs/operators';
import { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

export function preload(store: Store | StateContext<any>): Observable<any> {
  return store.dispatch(new EnableLoading());
}

export function startPreload<T>(store: Store | StateContext<any>): MonoTypeOperatorFunction<T> {
  return input$ => input$.pipe(tap(() => store.dispatch(new EnableLoading())));
}

export function stopPreload<T>(store: Store | StateContext<any>): MonoTypeOperatorFunction<T> {
  return input$ => input$.pipe(finalize(() => store.dispatch(new DisableLoading())));
}

export class GlobalStateModel {
  loadingCount?: number;
  currentSection?: string;
}

@State<GlobalStateModel>({
  name: 'global',
  defaults: { loadingCount: 0, currentSection: '' }
})
@Injectable()
export class GlobalState {
  constructor(private translate: TranslateService) { }

  @Selector()
  static loading(state: GlobalStateModel): boolean {
    return (state?.loadingCount || 0) > 0;
  }

  @Selector()
  static currentSection(state: GlobalStateModel): string {
    return state?.currentSection || '';
  }

  @Action(ResetCurrentSection)
  reset(ctx: StateContext<GlobalStateModel>): void {
    setTimeout(() => ctx.patchState({ currentSection: '' }));
  }

  @Action(SetCurrentSection)
  SetCurrentSection(ctx: StateContext<GlobalStateModel>, action: SetCurrentSection): Observable<any> {
    return this.translate.get(action.payload).pipe(
      delay(0),
      tap(currentSection => ctx.patchState({ currentSection }))
    );
  }

  @Action(ResetLoading)
  ResetLoading(ctx: StateContext<GlobalStateModel>): void {
    ctx.patchState({ loadingCount: 0 });
  }

  @Action(EnableLoading)
  enableLoading(ctx: StateContext<GlobalStateModel>): void {
    setTimeout(() => {
      const currentCount: number  = ctx.getState().loadingCount;
      ctx.patchState({ loadingCount: currentCount + 1 });
    });
  }

  @Action(DisableLoading)
  disableLoading(ctx: StateContext<GlobalStateModel>): void {
    setTimeout(() => {
      const currentCount: number = ctx.getState().loadingCount;
      ctx.patchState({ loadingCount: (currentCount > 0) ? currentCount - 1 : 0 });
    });
  }
}
