// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
}
:host .content {
  background-color: rgba(212, 19, 103, 0.75);
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 75px;
  line-height: 75px;
  z-index: 500;
  display: flex;
  justify-content: center;
}
:host.no-background .content {
  background-color: transparent;
}
:host.no-background .spacer {
  display: none;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFjdGlvbnMuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRSxXQUFBO0FBREY7QUFHRTtFQUNFLDBDQUFBO0VBQ0EsZUFBQTtFQUNBLFNBQUE7RUFDQSxXQUFBO0VBQ0EsWUFBQTtFQUNBLGlCQUFBO0VBQ0EsWUFBQTtFQUNBLGFBQUE7RUFDQSx1QkFBQTtBQURKO0FBS0k7RUFDRSw2QkFBQTtBQUhOO0FBTUk7RUFDRSxhQUFBO0FBSk4iLCJmaWxlIjoiYWN0aW9ucy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgJ3ZhcnMnO1xuXG46aG9zdCB7XG4gIHdpZHRoOiAxMDAlO1xuXG4gIC5jb250ZW50IHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiByZ2JhKCRjb2xvci1hdWRpcywgMC43NSk7XG4gICAgcG9zaXRpb246IGZpeGVkO1xuICAgIGJvdHRvbTogMDtcbiAgICB3aWR0aDogMTAwJTtcbiAgICBoZWlnaHQ6IDc1cHg7XG4gICAgbGluZS1oZWlnaHQ6IDc1cHg7XG4gICAgei1pbmRleDogNTAwO1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gIH1cblxuICAmLm5vLWJhY2tncm91bmQge1xuICAgIC5jb250ZW50IHtcbiAgICAgIGJhY2tncm91bmQtY29sb3I6IHRyYW5zcGFyZW50O1xuICAgIH1cblxuICAgIC5zcGFjZXIge1xuICAgICAgZGlzcGxheTogbm9uZTtcbiAgICB9XG4gIH1cbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/page-structure/actions/actions.component.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;AADF;AAGE;EACE,0CAAA;EACA,eAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;AADJ;AAKI;EACE,6BAAA;AAHN;AAMI;EACE,aAAA;AAJN;AACA,w+BAAw+B","sourcesContent":["@import 'vars';\n\n:host {\n  width: 100%;\n\n  .content {\n    background-color: rgba($color-audis, 0.75);\n    position: fixed;\n    bottom: 0;\n    width: 100%;\n    height: 75px;\n    line-height: 75px;\n    z-index: 500;\n    display: flex;\n    justify-content: center;\n  }\n\n  &.no-background {\n    .content {\n      background-color: transparent;\n    }\n\n    .spacer {\n      display: none;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
