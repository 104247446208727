import { Component, OnDestroy, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnChanges, OnDestroy {
  @Output() newKey = new EventEmitter();
  @Input() key: string;
  searchBox: FormControl = new FormControl();
  private sub: Subscription | undefined;

  ngOnChanges(changes: SimpleChanges) {
    if (!this.sub) {
      this.sub = this.searchBox.valueChanges.pipe(
        distinctUntilChanged(),
        debounceTime(500)
      ).subscribe(key => this.newKey.emit(key));
    }
    // update external value if changed
    // this method will be typically called on load,
    // it will emit on empty string, causing data to be automatically updated
    if (changes && changes.key && changes.key.currentValue != null && changes.key.currentValue !== changes.key.previousValue) {
      this.searchBox.setValue(changes.key.currentValue);
    }
  }

  clearSearch() {
    this.searchBox.setValue('');
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
}
